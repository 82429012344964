import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import UnProjet from "../components/Common/UnProjet"
import Offres  from  "../components/SiteInternet/Offres"
import { Helmet } from "react-helmet"

const OffreSite = () => {
  return (
    <Layout>
      <Helmet>
        <title>Seekreet | Offre Site Internet</title>
        <meta name="description" content="Seekreet. Vous souhaitez créer un site internet ? Vendre sur internet ? Développer votre activité sur le web ? Nos experts sauront vous écouter pour répondre à vos besoins. Découvrez nos offres !" />
      </Helmet>
      <Navbar />
      <PageBanner
          pageTitle="Offre Site internet" 
          homePageText="Accueil" 
          homePageUrl="/" 
          parentActive="Offres" 
          parentPageUrl="/offres"
          activePageText="Offre site internet" 
      />
      <Offres />
      <UnProjet />
      <Footer />
    </Layout>
  )
}

export default OffreSite